/* Hero section styling */
.hero {
  background-image: url('../image/hero-image.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 64px);
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero-searchform {
  width: 100%;
  height: 20%;
  background-color: #000;
  display: flex;
  justify-content: center;
}

.hero-title {
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  text-align: center;
}

.hero-subtitle {
  font-size: 1.25rem;
  font-weight: normal;
}

/* Mobile responsiveness */
@media (max-width: 960px) {
  .hero {
    height: 70vh;
  }

  .hero-title {
    font-size: 2rem !important;
  }
}

@media (max-width: 600px) {
  .hero {
    height: 60vh;
  }

  .hero-title {
    font-size: 1.5rem !important;
  }

  .hero-searchform {
    flex-direction: column;
  }
}
